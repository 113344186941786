/* Nav bar Admin panel */

#navBarAdmin {
    background-color: #002f4e;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px
}

#titleAdminPanel {
    font-size: 2.8rem;
    line-height: 0.9;
}

#subtitleAdminpanel {
    /* font-weight: bold; */
    font-family: "Bevan", cursive;
    color: #FBAF00;
}

#navBarAdmin .logoutIcon {
    transform: scale(1.4);
    color: white;
}

#navBarAdmin .logoutIcon:hover {
    color: #FBAF00
}

/* Content admin panel */

#containerAdmin {
    background-color: #016FB9;
}

#containerAdmin .typoH2AdminPanel {
    padding: 30px 0 0 16px;
}

#containerAdmin .typoH3AdminPanel {
    padding: 30px 0 0 16px;
}

#containerAdmin .typoSubtitleAdminPanel {
    padding: 0 16px 0 16px;
}


#containerAdmin .listItemTextBold {
    font-weight: bold !important;
}

#containerAdmin .listItemPrice, #containerAdmin .listItemHoliday, #containerAdmin .listItemReservation {
    display: flex;
    flex-direction: column;
}

#containerAdmin .listItemPriceContent, #containerAdmin .listItemHolidayContent, #containerAdmin .listItemReservationContent {
    display: flex;
    width: 100%;
}

#containerAdmin .typoTitleFormReservation {
    margin-top: 20px !important;
}

#containerAdmin .buttonAddForm {
    margin: 10px 0 0 16px !important;
}

#containerAdmin .boxFormReservation, #containerAdmin .boxFormHoliday {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

#containerAdmin .inputYearHoliday {
    margin: 10px 0 0 0 !important;
}

#containerAdmin .inputLibelleReservation, #containerAdmin .inputSelectHoliday {
    margin: 15px 0 0 0 !important;
}

#containerAdmin .inputYearHoliday {
    width: 100px
}

#containerAdmin .inputDateReservation, .inputDateHoliday {
    margin: 10px 0 10px 0 !important;
}

#containerAdmin .seasonItemPrice {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0 !important;
    margin: 0 10px 0 10px;
    width: 50px;
}

#containerAdmin .boxEditIconAdminPrice {
    display: flex;
    align-items: center;
}

#containerAdmin .iconAdminPrice, #containerAdmin .iconAdminHoliday, #containerAdmin .iconAdminReservation {
    transform: scale(1.2);
    margin: auto;
    color: white;
}

#containerAdmin .iconAdminPrice:hover, #containerAdmin .iconAdminHoliday:hover, #containerAdmin .iconAdminReservation:hover {
    cursor: pointer;
    color: #FBAF00;
}

#containerAdmin .dividerAdmin {
    width: 100%;
    margin-top: 13px;
}

.boxModificationPrice {
    margin: 20px 0 20px 0;
    padding: 15px;
    width: 95%;
    background-color: #009dff;
    border-radius: 6px;
    display: none;
    position: relative;
}

#containerAdmin .iconCloseModification {
    position: absolute;
    top: 15px;
    right: 15px;
    color: black;
}

#boxModificationDisplay {
    display: block;
}

#containerAdmin .boxModificationDate {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px
}

#containerAdmin .boxModificationDateContent {
    width: 250px;
}

#containerAdmin .boxModificationJourMois {
    display:flex;
}

#containerAdmin .typoDateStartEnd {
    margin: 15px 0 10px 0 !important;
}

#containerAdmin .inputFormModificationPrice {
    margin: 5px 0 0 5px !important;
}

#containerAdmin .textFieldFormModificationPrice {
    width: 80px;
}

#boxModificationHolidays2 {
    margin-top: 30px
}

#boxFormAdminPrice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0 20px 0;
}

#containerAdmin .typoPickSeason {
    text-decoration: underline;
}

#containerAdmin .typoPickSeason:hover {
    cursor: pointer;
    color:#FBAF00;
}

#containerAdmin .centerButtonAdmin {
    display: flex;
    justify-content: center;
    margin: 40px 0 20px 0;
}

#containerAdmin .inputFormAdminPrice {
    width: 90px;
}

/* Extra Small and Small devices (phones, less 600px) */
@media only screen and (max-width: 600px) {
    #containerAdmin .typoH3AdminPanel {
        font-size: 1.8rem !important;
    }
}

/* Medium devices (portrait tablets and large phones, between 600px and 768px) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    #titleAdminPanel {
        font-size: 3rem;
    }

    #containerAdmin .typoH2AdminPanel {
        font-size: 2.4rem !important;
    }

    #containerAdmin .typoH3AdminPanel {
        font-size: 1.9rem !important;
    }

    #typoModificationAdmin {
        font-size: 2.1rem;
    }
}

/* Large and extra large devices (landscape tablets, up 768px) */
@media only screen and (min-width: 768px) {
    
}

/* Large devices (landscape tablets, between 768px and 992px) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    #titleAdminPanel {
        font-size: 3.2rem;
    }

    #containerAdmin .typoH2AdminPanel {
        font-size: 2.5rem !important;
    }

    #containerAdmin .typoH3AdminPanel {
        font-size: 2rem !important;
    }

    #typoModificationAdmin {
        font-size: 2.2rem;
    }
}

/* Extra large devices (laptops/desktops, between 992px and 1200px) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    #titleAdminPanel {
        font-size: 3.4rem;
    }

    #containerAdmin .typoH2AdminPanel {
        font-size: 2.6rem !important;
    }

    #containerAdmin .typoH3AdminPanel {
        font-size: 2.1rem !important;
    }

    #typoModificationAdmin {
        font-size: 2.25rem;
    }
}

/* Extra extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    #containerAdmin .boxModificationDate {
        flex-direction: row;
        justify-content: center;
        margin-bottom: 30px
    }

    #containerAdmin .typoDateStartEnd {
        margin-left: 20px;
    }

    #titleAdminPanel {
        font-size: 3.8rem;
    }

    #containerAdmin .typoH2AdminPanel {
        font-size: 2.8rem !important;
    }

    #containerAdmin .typoH3AdminPanel {
        font-size: 2.2rem !important;
    }

    #typoModificationAdmin {
        font-size: 2.3rem;
    }
}