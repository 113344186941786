#banner {
    height : 100vh;
    width : 100vw;
    background-image: url("../Images/Welcome/banner.webp");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
}

#banner-title  {
    height: 55%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

#banner .typoTitleWelcome {
    margin-left: 5px;
}

#banner .typoSubtitleWelcome {
    margin-right: 5px;
    font-size: 1rem;
    line-height: 1.5;
    font-family: 'Oxygen';
}

#banner .boxMotionSubtitle {
    height: 40%;
    display: flex;
    justify-content: flex-end;
}

#banner .boxSubtitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    text-shadow: 0px 0px 4px grey;
    margin-top: 80px;
}

.boxButtonWelcome .buttonWelcome  {
    margin-top: 10px;
}

#banner .boxCaption {
    height: 5%;
    display: flex;
    align-items: center;
}

#banner .caption  {
    font-style: italic;
    margin-left: 10px;
}

/* MEDIA QUERIES */

/* DEFAULT */
/* Extra small devices (phones, 480px and down) */
@media only screen and (max-width: 480px){
    #banner .caption  {
        visibility: hidden;
    }

    #banner .boxSubtitle2 {
        width: 60%;
    }

    #banner .typoTitleWelcome {
        font-size: 2.8rem;
    }
}

/* Medium devices (portrait tablets and large phones, between 600px and 768px) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    #banner .typoSubtitleWelcome {
        font-size: 1.05rem;
    }

}

/* Large devices (landscape tablets, between 768px and 992px) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    #banner .typoSubtitleWelcome {
        font-size: 1.1rem;
    }

}

/* Medium and large devices (landscape tablets, between 768px and 1500px) */
@media only screen and (min-width: 768px) and (max-width: 1500px) {
    #banner-title {
        width: 80%;
    }
}

/* Extra large devices (laptops/desktops, between 992px and 1200px) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    #banner .boxSubtitle2  {
        width: 40%;
    }

    #banner .typoSubtitleWelcome {
        font-size: 1.2rem;
    }
}

/* Extra extra large devices (large laptops and desktops, between 1200px and 1500px) */
@media only screen and (min-width: 1200px) and (max-width: 1500px) {
    #banner .boxSubtitle2  {
        width: 40%;
    }

    #banner .typoSubtitleWelcome {
        font-size: 1.4rem;
    }
}

/* Extra extra extra large devices (extra large laptops, 1500px and up) */
@media only screen and (min-width: 1500px) {
    #banner .boxSubtitle2  {
        width: 35%;
    }

    #banner .typoSubtitleWelcome {
        font-size: 1.5rem;
    }
}