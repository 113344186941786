#boxFooter {
    background-color: #FFF;
    padding: 10px;
}

#containerFooter1 {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
}

#linkMailFooter {
    margin: 0 auto 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

#linkMailFooter:hover #typoMailFooter {
    color: rgb(27, 27, 27);
    text-decoration: underline;
}

#linkMailFooter:hover #iconMailFooter {
    color: rgb(27, 27, 27);
}

#iconMailFooter {
    color: black;
    margin-right: 8px;
}

#containerFooter2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#boxFooter .typoFooter {
    width: 100%;
}

#boxFooter .leftFooter {
    text-align: left;
}

#boxFooter .centerFooter {
    margin: 0 10px 0 10px;
    text-align: center;
}

#boxFooter .rightFooter {
    text-align: right;
}

#boxFooter .linkFooter {
    cursor: pointer;
}