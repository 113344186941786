#sectionRecommendations {
    padding-top: 0px !important;
}

#containerRecommendations {
    display: flex;
    width: 100%;
}

#containerRecommendations img {
    width: 100%;
}

#sectionRecommendations .boxRecommendation1, #sectionRecommendations .boxRecommendation2 {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

#sectionRecommendations .boxRecommendation1 {
    margin-right: 20px;
}

#sectionRecommendations .boxRecommendation1-1 {
    background-image: url("../Images/Recommendations/plongee.webp");
}

#sectionRecommendations .boxRecommendation1-2 {
    background-image: url("../Images/Recommendations/anseArlet.webp");
}

#sectionRecommendations .boxRecommendation1-3 {
    background-image: url("../Images/Recommendations/navette.webp");
}

#sectionRecommendations .boxRecommendation1-4 {
    background-image: url("../Images/Recommendations/centreEquestre.webp");
}

#sectionRecommendations .boxRecommendation2-1 {
    background-image: url("../Images/Recommendations/anseDufour.webp");
}

#sectionRecommendations .boxRecommendation2-2 {
    background-image: url("../Images/Recommendations/savaneEsclave.webp");
}

#sectionRecommendations .boxRecommendation2-3 {
    background-image: url("../Images/Recommendations/villagePoterie.webp");
}

#sectionRecommendations .boxRecommendation2-4 {
    background-image: url("../Images/Recommendations/locationBateau.webp");
}

#sectionRecommendations .boxRecommendationImage {
    margin-bottom: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    cursor: pointer;
}

#sectionRecommendations .boxRecommendation1-1, #sectionRecommendations .boxRecommendation1-3, #sectionRecommendations .boxRecommendation2-2, #sectionRecommendations .boxRecommendation2-4 {
    flex-grow: 2;
}

#sectionRecommendations .boxRecommendation1-2, #sectionRecommendations .boxRecommendation1-4, #sectionRecommendations .boxRecommendation2-1, #sectionRecommendations .boxRecommendation2-3 {
    flex-grow: 1;
}

#sectionRecommendations .boxRecommendation1-1, #sectionRecommendations .boxRecommendation1-3 {
    margin-left: 20%;
}

#sectionRecommendations .boxRecommendation2-2, #sectionRecommendations .boxRecommendation2-4 {
    margin-right: 20%;
}

#sectionRecommendations .boxRecommendation1-2, #sectionRecommendations .boxRecommendation1-4 {
    margin-left: 5%;
}

#sectionRecommendations .boxRecommendation2-1, #sectionRecommendations .boxRecommendation2-3 {
    margin-right: 5%;
}

#sectionRecommendations .typoRecommendation1 {
    margin-left: 10px;
}

#sectionRecommendations .typoRecommendation2 {
    margin-left: 20px;
}

.boxRecommendationImage:hover .typoRecommendation1 {
    text-shadow: 0px 0px 4px black;
}

.boxRecommendationImage:hover .typoRecommendation2 {
    text-shadow: 0px 0px 4px black;
}

.boxRecommendationDetail {
    display: none;
}

#boxDetailDisplay {
    display: block;
}

#sectionRecommendations .detail1-1, #sectionRecommendations .detail1-2, #sectionRecommendations .detail1-3, #sectionRecommendations .detail1-4 {
    margin: 0 0 20px 10px;
}

#sectionRecommendations .detail2-1, #sectionRecommendations .detail2-2, #sectionRecommendations .detail2-3, #sectionRecommendations .detail2-4 {
    margin: 0 10px 20px;
}

/* MEDIA QUERIES */

/* DEFAULT */
/* Extra small devices (phones, 480px and down) */
@media only screen and (max-width: 480px){
    #sectionRecommendations .typoRecommendation1 {
        font-size: 16px;
    }

    #sectionRecommendations .typoRecommendation2 {
        font-size: 20px;
    }
}

/* All devices exept extra small devices (phones, between 480px and 600px) */
@media only screen and (min-width: 480px) {
    #sectionRecommendations .boxRecommendation1-1, #sectionRecommendations .boxRecommendation1-3 {
        margin-left: 25%;
    }
    
    #sectionRecommendations .boxRecommendation2-2, #sectionRecommendations .boxRecommendation2-4 {
        margin-right: 25%;
    }
    
    #sectionRecommendations .boxRecommendation1-2, #sectionRecommendations .boxRecommendation1-4 {
        margin-left: 10%;
    }
    
    #sectionRecommendations .boxRecommendation2-1, #sectionRecommendations .boxRecommendation2-3 {
        margin-right: 10%;
    }
}

/* Small devices (phones, between 480px and 600px) */
@media only screen and (min-width: 480px) and (max-width: 600px) {
    #sectionRecommendations .typoRecommendation1 {
        font-size: 18px;
    }

    #sectionRecommendations .typoRecommendation2 {
        font-size: 24px;
    }
}

/* Medium devices (portrait tablets and large phones, between 600px and 768px) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    #sectionRecommendations .typoRecommendation1 {
        font-size: 20px;
    }

    #sectionRecommendations .typoRecommendation2 {
        font-size: 28px;
    }
}

/* Large devices (landscape tablets, between 768px and 992px) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    #sectionRecommendations .boxRecommendation1, #sectionRecommendations .boxRecommendation2 {
        height: 110vh;
    }

    #sectionRecommendations .typoRecommendation1 {
        font-size: 22px;
    }

    #sectionRecommendations .typoRecommendation2 {
        font-size: 36px;
    }
}

/* Extra large devices (laptops/desktops, between 992px and 1200px) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    #sectionRecommendations .boxRecommendation1, #sectionRecommendations .boxRecommendation2 {
        height: 130vh;
    }

    #sectionRecommendations .typoRecommendation1 {
        font-size: 28px;
    }

    #sectionRecommendations .typoRecommendation2 {
        font-size: 40px;
    }
}

/* Extra extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    
    #sectionRecommendations .boxRecommendation1-1, #sectionRecommendations .boxRecommendation1-3 {
        margin-left: 35%;
    }
    
    #sectionRecommendations .boxRecommendation2-2, #sectionRecommendations .boxRecommendation2-4 {
        margin-right: 35%;
    }
    
    #sectionRecommendations .boxRecommendation1-2, #sectionRecommendations .boxRecommendation1-4 {
        margin-left: 18%;
    }
    
    #sectionRecommendations .boxRecommendation2-1, #sectionRecommendations .boxRecommendation2-3 {
        margin-right: 18%;
    }

    #sectionRecommendations .boxRecommendation1, #sectionRecommendations .boxRecommendation2 {
        height: 120vh;
    }

    #sectionRecommendations .typoRecommendation1 {
        font-size: 30px;
    }

    #sectionRecommendations .typoRecommendation2 {
        font-size: 44px;
    }
}
