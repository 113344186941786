#typoTitleReservation {
    margin: 0 0 30px 10px;
}

#boxDatePrixResult {
    display: flex;
    justify-content: space-between;
    margin: 0 10% 0 10%;
}

#sectionResult .typoDateResult {
    font-size: 1.25rem;
}

#boxDateResult .boldDate {
    font-weight: bold;
}

#boxPrixResult {
    display: flex;
    flex-direction: column;
}

#sectionResult .buttonDetailPrix {
    margin: 10px auto 0 auto;
    font-weight: bold;
}

#boxDetailPrix {
    display:flex;
    flex-direction: column;
    margin: 30px 15% 0 15%;
    background-color: #fbaf00;
    border-radius: 6px;
    padding: 20px 20px;
    position: relative;
}

#sectionResult .typoTitleDetail {
    margin-bottom: 10px;
}

#sectionResult .boxDetailPrixElement {
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 10px;
}

#sectionResult .typoPriceDetailElement {
    white-space: nowrap;
    margin-left: 5px;
}

#sectionResult .iconCloseDetailResult {
    color: black;
    transform: scale(1.2);
    position: absolute;
    top: 10px;
    right: 10px;
}

.iconCloseDetailResult:hover {
    cursor: pointer
}

#sectionResult .typoForm {
    margin: 100px 0 0 10px;
    text-align: center;
}

#sectionResult .typoForm2 {
    margin-left: 10px;
    text-align: center;
}

#sectionResult .boxForm {
    display: flex;
    flex-direction: column;
    margin: 30px 10% 0 10%;
}

#sectionResult #boxConditionReservation {
    margin: 30px auto 0 auto;
    width: max-content;
}

#sectionResult .boxButtonreservation {
    display: flex;
}

#sectionResult #buttonReservation {
    margin: 50px auto 0 auto;
}

/* MEDIA QUERIES */

/* Extra extra small devices (phones, down 370px) */
@media only screen and (max-width: 370px) {
    #boxDatePrixResult {
        flex-direction: column;
        text-align: center;
    }

    #boxDateResult {
        margin-bottom: 30px;
    }

    #sectionResult .boxDetailPrixElement {
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    #boxDetailPrix {
        padding: 20px 10px;
    }

    #sectionResult .boxForm {
        margin-left: 10px;
        margin-right: 10px;
    }
}

/* Extra small devices (phones, down 480px) */
@media only screen and (max-width: 480px) {
    #boxDatePrixResult {
        margin-right: 10px;
        margin-left: 10px;
    }

    #boxDetailPrix {
        margin-right: 10px;
        margin-left: 10px;
    }

    #sectionResult .boxDetailPrixElement {
        margin-left: 5px;
        margin-right: 5px;
    }
}

/* Small devices (phones, between 480px and 600px) */
@media only screen and (min-width: 480px) and (max-width: 600px) {
    #boxDetailPrix {
        margin-right: 5%;
        margin-left: 5%;
    }

    #sectionResult .boxForm {
        margin-left: 20%;
        margin-right: 20%;
    }
}

/* Medium devices (portrait tablets and large phones, between 600px and 768px) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    #sectionResult .typoDateResult {
        font-size: 1.4rem;
    }

    #sectionResult .buttonDetailPrix {
        font-size: 1rem;
    }

    #sectionResult .boxForm {
        margin-left: 25%;
        margin-right: 25%;
    }
}

/* Large devices (landscape tablets, between 768px and 992px) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    #sectionResult .typoDateResult {
        font-size: 1.45rem;
    }

    #sectionResult .buttonDetailPrix {
        font-size: 1.05rem;
    }

    #sectionResult .boxForm {
        margin-left: 30%;
        margin-right: 30%;
    }
}

/* Extra large devices (laptops/desktops, between 992px and 1200px) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    #sectionResult .typoDateResult {
        font-size: 1.5rem;
    }

    #sectionResult .buttonDetailPrix {
        font-size: 1.1rem;
    }

    #sectionResult .boxForm {
        margin-left: 35%;
        margin-right: 35%;
    }
}

/* Extra extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    #sectionResult .typoDateResult {
        font-size: 1.55rem;
    }

    #sectionResult .buttonDetailPrix {
        font-size: 1.15rem;
    }

    #sectionResult .boxForm {
        margin-left: 35%;
        margin-right: 35%;
    }
}