#sectionInformations .imageCarousel {
    width: 100vw;
}

#sectionInformations .containerStrongPoints {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#sectionInformations .boxStrongPoints {
    margin: 10px 0 0 0
}

#sectionInformations .boxStrongPoint {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0 40px 0;
}

#sectionInformations .iconStrongPoint {
    width: 50px;
    height: 50px;
    margin-right: 20px
}

#sectionInformations .typoStrongPoint {
    width: 50%;
    font-weight: bold;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  color: #FFF !important;
}

.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: #FBAF00 !important;
}

/* MEDIA QUERIES */

/* DEFAULT */
/* Extra small devices (phones, 480px and down) */
@media only screen and (max-width: 480px){
    #sectionInformations .typoStrongPoint {
        font-size: 18px;
    }
}

/* Small devices (phones, between 480px and 600px) */
@media only screen and (min-width: 480px) and (max-width: 600px) {
    #sectionInformations .typoStrongPoint {
        font-size: 20px;
    }
}

/* Medium devices (portrait tablets and large phones, between 600px and 768px) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    #sectionInformations .typoStrongPoint {
        font-size: 22px;
    }

    #sectionInformations .iconStrongPoint {
        width: 60px;
        height: 60px;
        margin-right: 25px
    }
}

/* Large devices (landscape tablets, between 768px and 992px) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    #sectionInformations .imageCarousel {
        width: 50vw;
    }

    #sectionInformations .typoStrongPoint {
        font-size: 24px;
    }

    #sectionInformations .iconStrongPoint {
        width: 70px;
        height: 70px;
        margin-right: 30px;
    }
}

/* Extra large devices (laptops/desktops, between 992px and 1200px) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    #sectionInformations  .imageCarousel {
        width: 50vw;
    }

    #sectionInformations  .typoStrongPoint {
        font-size: 26px;
    }

    #sectionInformations  .iconStrongPoint {
        width: 80px;
        height: 80px;
    }
}

/* Extra extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    #sectionInformations  .imageCarousel {
        width: 33.33vw;
    }

    #sectionInformations  .typoStrongPoint {
        font-size: 28px;
    }

    #sectionInformations  .iconStrongPoint {
        width: 90px;
        height: 90px;
        margin-right: 35px;
    }
}