#containerTestimony {
    display: flex;
    flex-direction: column;
}

#boxImageHost {
    display: flex;
}

#imageHost {
    margin: auto;
    height: auto;
    width: 50vw;
}

#sectionTestimony .boxTestimony {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    padding-left: 40px;
}

#sectionTestimony .typoNameHost {
    font-style: italic;
    font-weight: bold;
    font-size: 1.25rem;
}

#sectionTestimony .typoInvitation {
    text-align: center;
    margin-top: 10vh;
    margin-bottom: 5vh;
    padding-right: 20px;
    padding-left: 20px;
    font-weight: bold;
}

#sectionTestimony .typoInvitationSpan {
    color: #fbaf00;
    cursor: pointer;
}

#sectionTestimony .boxArrowDownIcon {
    display: flex;
}

#arrowDownIcon {
    font-size: 5rem;
    color: #fbaf00;
    margin: auto;
    cursor: pointer;
}

#sectionTestimony .guillemet {
    font-size: 60px;
    font-weight: bold;
}

#sectionTestimony .guillemet1 {
    line-height: 20px;
}

#sectionTestimony .guillemet2 {
    line-height: 60px;
    text-align: right;
}


/* Small devices (phones , between 480px and 600px) */
@media only screen and (min-width: 480px) and (max-width: 600px) {
    #imageHost {
        width: 40vw;
    }
}

/* Medium devices (phones , between 600px and 768px) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    #imageHost {
        width: 35vw;
    }
}

/* Extra small, small and medium devices (phones and tablets, down 768px) */
@media only screen and (max-width: 768px) {
    .boxTestimony {
        padding-right: 40px;
    }

    #sectionTestimony .typoNameHost {
        font-size: 1.1rem;
    }
}


/* Large and Extra large devices (laptops/desktops, up 768px) */
@media only screen and (min-width: 768px) {
    #containerTestimony {
        display: flex;
        flex-direction: row;
    }

    #boxImageHost {
        width: 100%;
    }
}

/* Large devices (landscape tablets, between 768px and 992px) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    #containerTestimony {
        padding: 0 5% 0 5%;
    }

    #imageHost {
        width: 30vw;
    }
}

/* Extra large devices (laptops/desktops, between 992px and 1200px) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    #containerTestimony {
        padding: 0 10% 0 10%;
    }

    #imageHost {
        width: 24vw;
    }
}

/* Extra extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    #containerTestimony {
        padding: 0 15% 0 15%;
    }

    #imageHost {
        width: 270px;
    }
}
