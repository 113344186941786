#scroll-dialog-title {
    font-size: 1.75rem !important;
    font-weight: bold;
    color: #FBAF00;
}

#sectionModalEquipement .titleModalEquipement {
    margin-bottom: 30px;
    font-size: 1.25rem;
}

#sectionModalEquipement .titleModalEquipement:first-of-type {
    margin-top: 20px;
}

#sectionModalEquipement .dividerModalEquipement {
    margin-bottom: 20px;
}

#sectionModalEquipement .iconEquipement {
    width: 40px;
}

#sectionModalEquipement .equipement {
    margin-bottom: 20px;
}

