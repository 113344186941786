#sectionLocation .boxPlane {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;
}

#sectionLocation .plane {
    width: 75px;
    height: 75px
}

#sectionLocation .boxMapMartinique {
    display: flex;
    flex-direction: column;
}

#sectionLocation .mapMartinique {
    width: 100px;
    margin-left: auto;
}

#sectionLocation  .typoAppartement {
    font-weight: bold;
}

#sectionLocation .typoMartinique {
    text-align: center;
    font-family: "Bevan","cursive";
    margin-top: -10px;
    font-size: 20px;
}

#sectionLocation .containerTroisIlets {
    display: flex;
    justify-content: center;
}

#sectionLocation .boxTroisIlets {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
}

#sectionLocation .troisIlets {
    margin: 20px 0 5px 0;
    width: 100%;
}

#sectionLocation .body1TroisIlets {
    margin-top: 40px;
}

#sectionLocation .buttonModalPlan {
    margin-top: 20px
}

/* MEDIA QUERIES */

/* Small devices (phones, between 480px and 600px) */
@media only screen and (min-width: 480px) and (max-width: 600px) {
    #sectionLocation .plane {
        width: 85px;
        height: 85px
    }

    #sectionLocation .mapMartinique {
        width: 125px;
    }

    #sectionLocation .boxTroisIlets {
        width: 70%;
    }
}

/* Medium devices (portrait tablets and large phones, between 600px and 768px) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    #sectionLocation .plane {
        width: 100px;
        height: 100px
    }
    
    #sectionLocation .mapMartinique {
        width: 150px;
    }

    #sectionLocation .boxTroisIlets {
        width: 60%;
    }
}

/* Large devices (landscape tablets, between 768px and 992px) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    #sectionLocation .plane {
        width: 125px;
        height: 125px
    }
    
    #sectionLocation .mapMartinique {
        width: 175px;
    }

    #sectionLocation .boxTroisIlets {
        width: 50%;
    }
}

/* Extra large devices (laptops/desktops, between 992px and 1200px) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    #sectionLocation .plane {
        width: 135px;
        height: 135px;
    }
    
    #sectionLocation .mapMartinique {
        width: 200px;
    }

    #sectionLocation .boxTroisIlets {
        width: 40%;
    }

    #sectionLocation .typoMartinique {
        font-size: 21px;
    }
}

/* Extra extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    #sectionLocation .plane {
        width: 150px;
        height: 150px;
    }
    
    #sectionLocation .mapMartinique {
        width: 225px;
    }

    #sectionLocation .boxTroisIlets {
        width: 40%;
        max-width: 700px;
    }

    #sectionLocation .typoMartinique {
        font-size: 22px;
    }
    
}
