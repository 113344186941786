#sectionSimulation .boxCalendar {
    display: flex;
}

#sectionSimulation .calendar {
    margin: 15px auto 50px auto;;
}

#sectionSimulation .containerCounter {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0 60px 0;
}

#sectionSimulation .boxCounter {
    align-items: center;
    display: flex;
}

#sectionSimulation .boxCounter:first-of-type {
    margin-bottom: 20px;
}

#boxButtonSimulation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

#buttonSimulation {
    display: flex;
    margin: 0 auto 0 auto;
    cursor: pointer;
}

#sectionSimulation .typoSubtitleSimulation {
    margin-left: 10px;
}

#sectionSimulation .typoCount1 {
    font-weight: bold;
    text-align: center;
}

#sectionSimulation .iconSimulation {
    margin: 0 10px 0 10px;
    color: "#FFF"
}

#sectionSimulation .disableIcon {
    cursor: not-allowed;
}

#sectionSimulation .typoCounter {
    width: 15px;
    text-align: center;
}

/* Calendar */
.rdrDayDisabled {
    background-color: #d32f2f;
}

.rdrDayDisabled .rdrDayNumber span {
    color: #000;
}

/* Extra large devices (laptops/desktops, between 992px and 1200px) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    #sectionSimulation .containerCounter {
        flex-direction: row;
        justify-content: center;
    }
    
    #sectionSimulation .boxCounter:first-of-type {
        margin: 0 30px 0 0;
    }
}

/* Extra extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    #sectionSimulation .containerSimulation {
        display: flex;
    }

    #sectionSimulation .boxDateSimulation, #sectionSimulation .boxPeopleSimulation {
        width: 100%
    }

    #sectionSimulation .typoSubtitleSimulation {
        text-align: center;
    }

    #sectionSimulation .boxCalendar {
        margin-top: 40px;
    }

    #sectionSimulation .containerCounter {
        margin-top: 55px;
    }
}