html{
  overflow-x: hidden !important;
}

.section {
  padding: 80px 0 80px 0 !important;
}

.title {
  margin: 0 10px 0 10px !important;
  padding-bottom : 80px !important;
  text-align: center !important;
}

.noWrap {
  white-space: nowrap !important;
}

.buttonBold {
  font-weight: bold !important;
}

.boxAlert {
  position: fixed !important;
  top: 10px !important;
  right: 10px !important;
  left: 10px !important;
  width: 100%-10px !important;
  z-index: 999 !important;
}

::-moz-selection { /* Code for Firefox */
  background: #ffd36b;
}

::selection {
  background: #ffd36b;
}