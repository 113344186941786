#modalImage {
    background-color: rgba(43, 43, 43, 0.8);
}

#modalImage .boxModalPlan {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#modalImage .boxCloseIconModal {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

#closeIconModal {
    margin-right: -10px;
    font-size: 3rem;
    color: white;
    cursor: pointer;
}

#modalImage .boxModalImg {
    position: relative;
}

#modalImage .imgModalPlan {
    max-width: 90vw;
    max-height: 70vh;
}


#modalImage .typoModalDistance {
    margin-top: 20px;
    font-weight: bold;
    text-align: center;
}

#modalImage .locationIconModal {
    position: absolute;
    color: #016FB9;
    font-size: 2rem;
}

#modalImage .locationTypoModal {
    position: absolute;
    color: #fff;
    font-weight: bold;
    text-shadow: 2px 0 #016FB9, -2px 0 #016FB9, 0 2px #016FB9, 0 -2px #016FB9,
             1px 1px #016FB9, -1px -1px #016FB9, 1px -1px #016FB9, -1px 1px #016FB9;
}

#locationIconModalAppart {
    position: absolute;
    color: #FBAF00;
    font-size: 2.5rem;
    top: 76%;
    right: 7%;
}

#locationTypoModalAppart {
    position: absolute;
    color: #FBAF00;
    font-weight: bold;
    top: 84%;
    right: 2%;
}


#locationIconModal1 {
    top: 22%;
    right: 54%;
}

#locationTypoModal1 {
    top: 27%;
    right: 53%;
}

#locationIconModal2 {
    top: 16%;
    right: 13%;
}

#locationTypoModal2 {
    top: 22%;
    right: 13%;
}

#locationIconModal3 {
    top: 35%;
    right: 4%;
}

#locationTypoModal3 {
    top: 40%;
    right: 4%;
}

#locationIconModal4 {
    top: 8%;
    right: 32%;
}

#locationTypoModal4 {
    top: 14%;
    right: 32%;
}

/* Extra small devices (phones, down 480px) */
@media only screen and (max-width: 480px) {
    #locationIconModal1 {
        top: 17%;
        right: 51%;
    }
    
    #locationIconModal2 {
        top: 12%;
        right: 12%;
    }
    
    #locationIconModal3 {
        top: 28%;
        right: 2%;
    }

    #locationIconModal4 {
        top: 7%;
        right: 32%;
    }

    #locationTypoModal4 {
        top: 1%;
        right: 16%;
    }

    #locationIconModalAppart {
        top: 68%;
        right: 4%;
    }
}

/* Small devices (phones, between 480px and 600px) */
@media only screen and (min-width: 480px) and (max-width: 600px) {
    #locationIconModal1 {
        top: 18%;
        right: 53%;
    }
    
    #locationIconModal2 {
        top: 13%;
        right: 12%;
    }
    
    #locationIconModal3 {
        top: 31%;
        right: 4%;
    }

    #locationIconModal4 {
        top: 6%;
        right: 32%;
    }

    #locationIconModalAppart {
        top: 71%;
        right: 5%;
    }
}

/* Medium devices (portrait tablets and large phones, between 600px and 768px) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
    #locationIconModal1 {
        top: 20%;
        right: 53%;
    }
    
    #locationIconModal2 {
        top: 15%;
        right: 12%;
    }
    
    #locationIconModal3 {
        top: 34%;
        right: 4%;
    }

    #locationIconModal4 {
        top: 7%;
        right: 32%;
    }

    #locationIconModalAppart {
        top: 73%;
        right: 7%;
    }
}